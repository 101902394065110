import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch, useSelector } from 'react-redux';
import { faCheckCircle, faInfoCircle, faMars, faVenus } from '@fortawesome/free-solid-svg-icons';
import { faCalendar } from '@fortawesome/free-regular-svg-icons';
import 'components/formio/formio.css';
import { Formio } from '@formio/react';
import './AddEventForm/form.css';
import { getDateInDDMMYYYY } from '../../constants/DateFunctions';
import { addNewSport } from '../slices/registrationStepperSlice';

Formio.setBaseUrl(window.location.origin);
const Productitem = ({
  index,
  sportName,
  eventName,
  icon,
  img,
  sportCategory,
  ageGroup,
  gender,
  price,
  status,
  statusDate,
  enabled,
  teamsCreated,
  statusMessage,
  subCategory,
}) => {
  const [isAdded, setIsAdded] = useState(false);
  const dispatch = useDispatch();
  const sportEventsAddedList = useSelector((state) => state.modal);
  const setSport = () => {
    setIsAdded(true);
  };

  return (
    <>
      <div className="xl:px-3 py-5 flex flex-row align-center justify-start border border-gray-200 rounded-lg">
        <img
          className="mr-2 xl:w-auto h-36 md:h-40 product-item-img rounded-lg"
          alt={sportName}
          src={img || null}
        />
        <div className="flex flex-col w-40 xl:w-auto justify-center mt-4 md:mt-0">
          <div className="flex justify-between">
            <h2 className="md:w-full text-sm md:text-lg mb-1 font-bold">
              {sportName} {icon}
            </h2>
          </div>
          <span className="text-sm md:text-sm text-gray-600 mt-1">
            {subCategory ? `(${subCategory})` : null}
          </span>{' '}
          <div className="text-xxs xl:text-xs flex gap-2 my-2">
            {gender ? (
              <button
                className={`${
                  gender === 'Male' ? 'bg-blue-50' : 'bg-pink-50'
                } rounded px-2 py-0.5 xl:py-1 flex items-center gap-2 `}
              >
                {gender === 'Male' ? (
                  <FontAwesomeIcon
                    icon={faMars}
                    className="text-blue-800 w-2.5 h-2.5 xl:w-3 xl:h-3"
                  />
                ) : (
                  <FontAwesomeIcon
                    icon={faVenus}
                    className="text-pink-750 w-2.5 h-2.5 xl:w-3 xl:h-3"
                  />
                )}
                <span className={`${gender === 'Male' ? 'text-blue-800' : 'text-pink-750'}`}>
                  {gender}
                </span>
              </button>
            ) : null}
            {ageGroup ? (
              <button className="rounded px-2 py-0.5 xl:py-1 bg-red-50 flex items-center gap-2">
                <FontAwesomeIcon
                  icon={faCalendar}
                  className="text-pink-750 w-2.5 h-2.5 xl:w-3 xl:h-3"
                />
                <span className="text-pink-750">{ageGroup}</span>
              </button>
            ) : null}
          </div>
          <div className="mt-4 flex flex-col xl:flex-row">
            <div className="">
              {status === 'Closed' ? null : (
                <>
                  <div className="text-blue-600">{teamsCreated}</div>
                  <div className="flex gap-1 items-end xl:block">
                    <h3 className="text-base xl:text-lg font-bold">Rs. {price}</h3>
                    <p className="contents text-xxs xl:text-xs text-gray-400">
                      Per {teamsCreated ? 'Team' : 'Player'}
                    </p>
                  </div>
                </>
              )}
            </div>
            <div>
              {!enabled && (
                <>
                  <div className="hidden xl:block">
                    {showEventStatus(status, statusDate, statusMessage)}
                  </div>
                  <div className="mt-1 xl:hidden block">
                    {showEventStatus(status, statusDate, statusMessage)}
                  </div>
                </>
              )}
            </div>
          </div>
          {/* Small Screen */}
          {status === 'Closed' ? (
            <div className="xl:hidden block" />
          ) : isAdded ||
            sportEventsAddedList.columns.find((item) => item.eventName === eventName) ? (
            <button className="text-green-450 px-auto py-1 mt-2 cursor-default xl:hidden block">
              <FontAwesomeIcon icon={faCheckCircle} className="mr-2" />
              <b>Added</b>
            </button>
          ) : (
            <button
              className="w-24 border border-black bg-white py-2 mt-2 rounded-lg transition-colors duration-500 hover:text-white hover:bg-black xl:hidden block font-bold"
              onClick={() => {
                setSport();
                console.log('event', eventName);
                console.log(
                  'redux',
                  sportEventsAddedList.columns.find((item) => item.eventName === eventName)
                );
                dispatch(
                  addNewSport({
                    index: sportEventsAddedList.columns.length,
                    key: index,
                    img: img,
                    sportName: sportName,
                    sportCategory: sportCategory,
                    gender: gender,
                    ageGroup: ageGroup,
                    eventName: eventName,
                  })
                );
              }}
            >
              Add Event
            </button>
          )}
        </div>

        {/* Large Screens */}
        {status === 'Closed' ? (
          <div className="xl:w-24 hidden xl:block" />
        ) : isAdded || sportEventsAddedList.columns.find((item) => item.eventName === eventName) ? (
          <button className="mt-auto w-24 text-green-450 px-auto py-2 cursor-default hidden xl:block">
            <FontAwesomeIcon icon={faCheckCircle} className="mr-2" />
            <b>Added</b>
          </button>
        ) : (
          <button
            className="mt-auto border border-black bg-white xl:w-24 py-2 rounded-lg transition-colors duration-500 hover:text-white hover:bg-black block hidden xl:block font-bold"
            onClick={() => {
              setSport();
              dispatch(
                addNewSport({
                  index: sportEventsAddedList.columns.length,
                  key: index,
                  img: img,
                  sportName: sportName,
                  sportCategory: sportCategory,
                  gender: gender,
                  ageGroup: ageGroup,
                  eventName: eventName,
                })
              );
            }}
          >
            Add Event
          </button>
        )}
      </div>
    </>
  );
};

export default Productitem;
export const ProductItemMobile = ({
  index,
  sportName,
  eventName,
  icon,
  img,
  sportCategory,
  ageGroup,
  gender,
  price,
  status,
  statusDate,
  enabled,
  teamsCreated,
  statusMessage,
  subCategory,
}) => {
  const [isAdded, setIsAdded] = useState(false);
  const dispatch = useDispatch();
  const sportEventsAddedList = useSelector((state) => state.modal);
  const setSport = () => {
    setIsAdded(true);
  };
  return (
    <div className="px-2 py-4 md:p-4 border-1 border-gray-250 rounded-lg w-47% md:w-48">
      <div
        className="h-48 w-full border-1 border-gray-250 rounded-lg"
        style={{
          background: `url('${img}')`,
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
        }}
      ></div>{' '}
      <div className="flex justify-between w-full text-sm font-semibold my-2">
        <div className="text-sm">
          {sportName}
          {icon}
        </div>
        <div>{price}</div>
      </div>
      {subCategory && <div className="teext-xs mt-2">{subCategory}</div>}
      <div className="text-xxs xl:text-xs flex gap-2 mt-2 mb-4">
        {gender ? (
          <button
            className={`${
              gender === 'Male' ? 'bg-blue-50' : 'bg-pink-50'
            } rounded px-2 py-0.5 xl:py-1 flex items-center gap-2 `}
          >
            {gender === 'Male' ? (
              <FontAwesomeIcon icon={faMars} className="text-blue-800 w-2.5 h-2.5 xl:w-3 xl:h-3" />
            ) : (
              <FontAwesomeIcon icon={faVenus} className="text-pink-750 w-2.5 h-2.5 xl:w-3 xl:h-3" />
            )}
            <span className={`${gender === 'Male' ? 'text-blue-800' : 'text-pink-750'}`}>
              {gender}
            </span>
          </button>
        ) : null}
        {ageGroup ? (
          <button className="rounded px-2 py-0.5 xl:py-1 bg-red-50 flex items-center gap-2">
            <FontAwesomeIcon
              icon={faCalendar}
              className="text-pink-750 w-2.5 h-2.5 xl:w-3 xl:h-3"
            />
            <span className="text-pink-750">{ageGroup}</span>
          </button>
        ) : null}
      </div>
      {status === 'Closed' ? (
        <div className="w-24 " />
      ) : isAdded || sportEventsAddedList.columns.find((item) => item.eventName === eventName) ? (
        <button className="text-green-450 px-auto py-1 mt-2 cursor-default">
          <FontAwesomeIcon icon={faCheckCircle} className="mr-2" />
          <b>Event Added</b>
        </button>
      ) : (
        <button
          className="border w-full border-black my-2 bg-white py-1 px-2 rounded-lg transition-colors duration-500 hover:text-white hover:bg-black block md:hidden font-bold"
          onClick={() => {
            setSport();
            console.log('event', eventName);
            console.log(
              'redux',
              sportEventsAddedList.columns.find((item) => item.eventName === eventName)
            );
            dispatch(
              addNewSport({
                index: sportEventsAddedList.columns.length,
                key: index,
                img: img,
                sportName: sportName,
                sportCategory: sportCategory,
                gender: gender,
                ageGroup: ageGroup,
                eventName: eventName,
              })
            );
          }}
        >
          Add Event
        </button>
      )}
    </div>
  );
};
const showEventStatus = (status, statusDate, statusMessage) => {
  switch (status) {
    case 'Participated':
      return (
        <>
          <div>
            <button className="mt-auto text-green-450 px-auto py-1 cursor-default block xl:hidden text-sm xl:text-base">
              <FontAwesomeIcon icon={faCheckCircle} className="mr-2" />
              <b>{status}</b>
            </button>
          </div>
          <button className="mt-auto ml-2.5 w-full text-green-450 px-auto py-2 cursor-default hidden xl:block">
            <FontAwesomeIcon icon={faCheckCircle} className="mr-2" />
            <b>{status}</b>
          </button>
        </>
      );
    case 'Added to Cart':
      return (
        <>
          <div>
            <button className="mt-auto text-green-450 px-auto py-1 cursor-default block xl:hidden text-sm xl:text-base">
              <FontAwesomeIcon icon={faCheckCircle} className="mr-2" />
              <b>{status}</b>
            </button>
          </div>
          <button className="mt-auto ml-2.5 w-full text-green-450 px-auto py-2 cursor-default hidden xl:block">
            <FontAwesomeIcon icon={faCheckCircle} className="mr-2" />
            <b>{status}</b>
          </button>
        </>
      );
    case 'Event is Full':
      return (
        <>
          <div>
            <button className="mt-auto text-green-450 px-auto py-1 cursor-default block xl:hidden text-sm xl:text-base">
              <b>{status}</b>
            </button>
          </div>
          <button className="mt-auto ml-2.5 w-full text-green-450 px-auto py-2 cursor-default hidden xl:block">
            <b>{status}</b>
          </button>
        </>
      );
    case 'Cart Limit Reached':
      return (
        <>
          <div>
            <button className="mt-auto text-green-450 px-auto py-1 cursor-default block xl:hidden text-sm xl:text-base">
              <b>{status}</b>
            </button>
          </div>
          <button className="mt-auto ml-2.5 w-full text-green-450 px-auto py-2 cursor-default hidden xl:block">
            <b>{status}</b>
          </button>
        </>
      );
    case 'Maximum limit Reached':
      return (
        <>
          <div>
            <button className="mt-auto text-green-450 px-auto py-1 cursor-default block xl:hidden text-sm xl:text-base">
              <FontAwesomeIcon icon={faInfoCircle} className="mr-2" />
              <b>{status}</b>
            </button>
          </div>
          <button className="mt-auto ml-2.5 w-full text-green-450 px-auto py-2 cursor-default hidden xl:block">
            <FontAwesomeIcon icon={faInfoCircle} className="mr-2" />
            <b>{status}</b>
          </button>
        </>
      );
    case 'Maximim limit Reached':
      return (
        <>
          <div>
            <button className="mt-auto text-green-450 px-auto py-1 cursor-default block xl:hidden text-sm xl:text-base">
              <FontAwesomeIcon icon={faInfoCircle} className="mr-2" />
              <b>{status}</b>
            </button>
          </div>
          <button className="mt-auto ml-2.5 w-full text-green-450 px-auto py-2 cursor-default hidden xl:block">
            <FontAwesomeIcon icon={faInfoCircle} className="mr-2" />
            <b>{status}</b>
          </button>
        </>
      );
    case 'Yet to start':
      return (
        <>
          <div>
            <h3 className=" w-full text-xs md:text-sm xl:text-base w-max font-bold">{status}</h3>
            <span className="contents text-xs text-gray-400">
              {statusMessage} {getDateInDDMMYYYY(statusDate)}
            </span>
          </div>
        </>
      );
    case 'Closed':
      return (
        <>
          <div>
            <h3 className=" w-full text-xs md:text-sm xl:text-base w-max font-bold">
              Registration Closed
            </h3>
            <span className="contents text-xs text-gray-400">
              {statusMessage} {getDateInDDMMYYYY(statusDate)}
            </span>
          </div>
        </>
      );

    default:
      break;
  }
};
